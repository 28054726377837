<template>
    <account-profile/>
</template>

<script>
import AccountProfile from '@components/accounts/Profile.vue';

export default {
    name: 'Show',

    components: { AccountProfile },
};
</script>
